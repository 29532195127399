<template>
    <div>
        <div class="elevation-1 pa-4 mb-4">
            <v-row>
                <v-col v-if="user.admin">
                    <v-select
                        v-model="filter.site_id"
                        label="Сайт"
                        :items="sites"
                        item-text="title"
                        item-value="id"
                        outlined
                        hide-details
                    />
                </v-col>
                <v-col>
                    <v-menu
                        ref="dateFromMenu"
                        v-model="dateFromMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="filter.date_from_formatted"
                                label="Дата"
                                prepend-icon="mdi-calendar"
                                hide-details
                                outlined
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="filter.date_from"
                            no-title
                            @input="setFilterDateFrom(filter.date_from)"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col>
                    <v-menu
                        ref="dateToMenu"
                        v-model="dateToMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="filter.date_to_formatted"
                                label="Дата"
                                prepend-icon="mdi-calendar"
                                hide-details
                                outlined
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="filter.date_to"
                            no-title
                            @input="setFilterDateTo(filter.date_to)"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <div class="d-flex justify-space-between">
                        <v-btn
                            color="primary"
                            class="mr-3"
                            @click="fetch"
                        >Применить</v-btn>
                        <v-btn
                            color="light"
                            class="mr-3"
                            @click="resetFilter"
                        >Сбросить</v-btn>
                        <v-spacer />
                        <v-btn
                            color="success"
                            @click="exportTo"
                        >Экспорт</v-btn>
                    </div>
                </v-col>
            </v-row>
        </div>
        <v-data-table
            :headers="headers"
            :items="orders"
            class="elevation-1"
            disable-sort
            :loading="loading"
            :server-items-length="entitiesCount"
            :items-per-page="itemsPerPage"
            :footer-props="{ 'itemsPerPageOptions': [10, 15, 30] }"
            @pagination="paginate"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Заказы</v-toolbar-title>
                </v-toolbar>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
                {{ item.created_at | moment('DD.MM.YYYY HH:mm') }}
            </template>
            <template v-slot:[`item.external_id`]="{ item }">
                <a href="" @click.prevent="open(item.id)">{{ item.external_id }}</a>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <span class="true-false-label primary" :style="`background: ${ statuses.orders[item.status].color } !important;`">
                    {{ statuses.orders[item.status].title }}
                </span>
            </template>
            <template v-slot:[`item.user`]="{ item }">
                {{ item.user.firstname }} {{ item.user.lastname }} {{ item.user.phone }}
            </template>
            <template v-slot:[`item.entity`]="{ item }">
                {{ item.entity.title }}
            </template>
            <template v-slot:[`item.payment`]="{ item }">
                {{ item.payment ? item.payment.title : '' }}
            </template>
            <template v-slot:[`item.delivery`]="{ item }">
                {{ item.delivery ? item.delivery.title : '' }}
            </template>
            <template v-slot:[`item.price`]="{ item }">
                {{ priceFormated(item.full_price) }}
            </template>
            <template v-slot:[`item.open`]="{ item }">
                <a :href="`/orders/${item.id}/detail`">
                    <v-icon color="primary">mdi-eye</v-icon>
                </a>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import moment from '../../plugins/moment';
    import store from '../../store';
    import { mapState } from 'vuex';

    import { priceFormated } from '../../helpers';

    export default {
        name: 'OrdersList',
        components: {
        },
        data: () => ({
            priceFormated,
            page: 1,
            itemsPerPage: 15,
            loading: true,
            headers: [
                { text: 'Дата', value: 'created_at' },
                { text: 'Номер', value: 'external_id' },
                { text: 'Статус', value: 'status' },
                { text: 'Пользователь', value: 'user' },
                { text: 'Бизнес', value: 'entity' },
                { text: 'Оплата', value: 'payment' },
                { text: 'Доставка', value: 'delivery' },
                { text: 'Сумма', value: 'price' },
                { text: '', align: 'right', value: 'open' },
            ],
            updateListTimerId: null,
            filter: {
                site_id: null,
                date_from: '',
                date_from_formatted: '',
                date_to: '',
                date_to_formatted: ''
            },
            dateFromMenu: false,
            dateToMenu: false,
        }),
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('sites', {
                sites: state => state.entities,
            }),
            ...mapState('orders', {
                statuses: state => state.statuses,
                orders: state => state.entities,
                entitiesCount: state => state.entitiesCount,
            }),
        },
        async mounted() {
            await store.dispatch('auth/fetch');
            if(this.user.id) {
                await store.dispatch('orders/statuses');
                if(this.user.admin) {
                    await store.dispatch('sites/fetch', {});
                }
                this.fetch();
                this.updateListTimerId = setInterval(this.fetch, 10000);
            }
        },
        beforeDestroy() {
            clearInterval(this.updateListTimerId);
        },
        methods: {
            setFilterDateFrom(date) {
                this.filter.date_from_formatted = moment(date).format('DD.MM.YYYY');
                this.dateFromMenu = false;
            },
            setFilterDateTo(date) {
                this.filter.date_to_formatted = moment(date).format('DD.MM.YYYY');
                this.dateToMenu = false;
            },
            getBadge (status) {
                return status ? 'success' : 'error';
            },
            async fetch() {
                this.loading = true;
                await store.dispatch('orders/fetch', { page: this.page, itemsPerPage: this.itemsPerPage, filter: this.filter });
                this.loading = false;
            },
            async exportTo() {
                this.loading = true;
                await store.dispatch('orders/export', { filter: this.filter });
                this.loading = false;
            },
            async paginate({ page, itemsPerPage }) {
                this.page = page;
                this.itemsPerPage = itemsPerPage;
                await this.fetch();
            },
            async open(id) {
                await this.$router.push({ name: 'orders-detail', params: { id } });
            },
            resetFilter() {
                this.filter = {
                    site_id: null,
                    date_from: '',
                    date_from_formatted: '',
                    date_to: '',
                    date_to_formatted: ''
                };
                this.fetch();
            }
        }
    }
</script>